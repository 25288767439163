import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

class TabBar extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    tabList: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string.isRequired,
      iconName: PropTypes.string.isRequired,
      tabName: PropTypes.string.isRequired,
      tabTitle: PropTypes.string.isRequired,
    })).isRequired,
    style: PropTypes.objectOf(PropTypes.string),
  }

  static defaultProps = {
    style: {},
  }

  renderTab = ({
    path: to, tabName, iconName, tabTitle,
  }) => {
    const active = this.props.activeTab === tabName
    const activeStr = active ? " is-active" : ""
    return <li
      key={tabName}
      className={`tabs-title mob-bt${activeStr}`}
      role="presentation"
    >
      <Link
        to={`${this.props.basePath}${to}`}
        aria-selected="true"
        className="title-experience-sidebar"
        role="tab"
        tabIndex={0}
      >
        <span className="title-ico">
          <svg className="svg-ico">
            <use xlinkHref={`#${iconName}`} />
          </svg>
        </span>
        {tabTitle}
      </Link>
    </li>
  }

  render() {
    const { tabList, style } = this.props
    return (
      <div className="cell">
        <ul
          className="tabs users-tabs"
          data-tabs
          id="apps-tabs"
          role="tablist"
          data-t="qjn1im-t"
          style={style}
        >
          {tabList.map(this.renderTab)}
        </ul>
      </div>
    )
  }
}

export { TabBar }
