import React from "react"
import PropTypes from "prop-types"

import BHCarousel, { Dots } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import "./Carousel.css"


class Carousel extends React.Component {
  static propTypes = {
    slides: PropTypes.arrayOf(PropTypes.node).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
  }

  render() {
    const {
      slides, onChange, value,
    } = this.props
    console.log({ value })
    return <div>
      <BHCarousel
        arrows
        clickToChange
        onChange={onChange}
        slides={slides}
        value={value}
      />
      <Dots value={value} onChange={onChange} number={slides.length} />
    </div>
  }
}


export { Carousel }
