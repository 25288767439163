const appTabList = [{
  iconName: "icon-info",
  tabTitle: "About",
  tabName: "about",
  path: "",
  // }, {
  //   iconName: "icon-manipulaton", // "icon-manipulation",
  //   tabTitle: "Instructions",
  //   tabName: "instructions",
  //   path: "instructions",
}]

module.exports = { appTabList }
