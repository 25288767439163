const dpappDefinition = ({
  id,
  name,
  title,
  url = null,
  topics,
  packageInfo: {
    version,
    description,
    author: {
      name: authorName,
    },
  },
}) => ({
  id,
  name,
  title,
  topics,
  url,
  packageInfo: {
    version,
    description,
    author: {
      name: authorName,
    },
  },
})

const dpappValidation = ({
  name,
  title,
  url,
  topics,
  packageInfo: {
    version,
    description,
    author: {
      name: authorName,
    },
  },
}) => {
  const isString = (arg) => typeof arg === "string"
  const isArray = (arg) => arg instanceof Array

  const error = (msg) => { throw new Error(`dpappValidation: ${msg}`) }

  if (!isString(name)) error("name should be a string")
  if (!isString(title)) error("title should be a string")
  if (url && !isString(url)) error("if provided, url should be a string")
  if (!isString(version)) error("packageInfo.version should be a string")
  if (!isString(description)) error("packageInfo.description shld be a string")
  if (!isString(authorName)) error("packageInfo.author.name should be a string")

  if (!isArray(topics) || !topics.every(isString)) {
    error("topics should be an array of strings")
  }
}

module.exports = { dpappDefinition, dpappValidation }
