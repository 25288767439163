const SwitchCase = ({ on, renderObj, defaultRender }) => {
  let render = renderObj[on]
  if (render === undefined) {
    if (defaultRender) {
      render = defaultRender
    } else {
      console.error(`SwitchCase: Unhandeled value {${on}}`)
      return null
    }
  }
  return render()
}

export { SwitchCase }
