const topicList = [{
  groupTitle: "Function",
  groupList: [{
    name: "agent",
    title: "Agent",
  }, {
    name: "portal",
    title: "Portal",
  }, {
    name: "integration",
    title: "Integration",
  }],
}, {
  groupTitle: "Category",
  groupList: [{
    name: "crm",
    title: "CRM",
  }, {
    name: "cart",
    title: "Shopping Cart",
  }, {
    name: "sms",
    title: "SMS",
  }],
}]

const baseTopicList = [
  "fusion",
  "extension",
  "sso",
]

const allTopicNames = [].concat(
  baseTopicList,
  ...topicList.map(({ groupList }) => groupList.map(({ name }) => name))
)

const allowedTopics = new Set(allTopicNames)
const baseTopics = new Set(baseTopicList)

const topicListVerification = (topics, context) => {
  if (topics.filter((t) => baseTopics.has(t)).length !== 1) {
    console.warn(
      `Found a repository without any base topic or with several of them${""
      }\nbase topics: ${baseTopics
      }\ntopics: ${topics
      }\ncontext: ${context
      }`
    )
  }
}

module.exports = {
  allTopicNames,
  baseTopicList,
  topicList,

  allowedTopics,
  topicListVerification,
}
