import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

import {
  Image,
} from "./Image"

import { appsPath } from "../misc"

import { IconTriplet } from "./IconTriplet"

class AppEntry extends React.Component {
  static propTypes = {
    // id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(PropTypes.string),
    defaultIconFileList: PropTypes.arrayOf(PropTypes.object),
    active: PropTypes.bool.isRequired,
    installed: PropTypes.bool.isRequired,
    noIconTriplet: PropTypes.bool,
  }

  static defaultProps = {
    topics: [],
    defaultIconFileList: [],
    noIconTriplet: false,
  }

  classList = ["featured-item"]

  getClassName = () => {
    const { active, installed } = this.props
    const classList = this.classList.slice()

    if (active) classList.push("is-active")
    if (installed) classList.push("is-installed")

    return classList.join(" ")
  }

  renderIcon = () => {
    const { title, defaultIconFileList: [file] } = this.props
    return <Image
      alt={`${title} logo`}
      file={file}
    />
  }

  renderIconTriplet = () => {
    if (this.props.noIconTriplet) return null
    const activeTopics = {}
    this.props.topics.forEach((name) => { activeTopics[name] = true })
    return <IconTriplet active={activeTopics} />
  }

  renderDescription = () => {
    return <React.Fragment>
      <h4>{this.props.title}</h4>
      <p className="paragraph_small">
        {this.props.description}
      </p>
    </React.Fragment>
  }

  renderPrice = () => {
    return <span className="apps-price">Free</span>
  }

  renderInstalled = () => {
    return <span className="apps-installed">Installed</span>
  }

  renderViewButton = () => {
    const { name } = this.props
    return <Link
      to={appsPath({ name })}
      className="section-featured-apps__details-button"
    >
      View
    </Link>
  }

  render() {
    return <div className={this.getClassName()}>
      <div className="section-top-rated-apps__app-frame">
        <this.renderIcon />
      </div>
      <div className="section-top-rated-apps__info-wrapper">
        <this.renderDescription />
        <this.renderIconTriplet />
        <this.renderPrice />
        <this.renderInstalled />
        <this.renderViewButton />

        <svg className="svg-installed">
          <use xlinkHref="#icon-installed" />
        </svg>
      </div>
    </div>
  }
}

class TopAppEntry extends AppEntry {
  classList = ["featured-item", "cell", "large-3"]

  renderIcon = () => {
    const { title, defaultIconFileList: [file] } = this.props
    return <Image
      alt={`${title} logo`}
      file={file}
    />
  }

  render() {
    return <div className={this.getClassName()}>
      <div className="section-top-rated-apps__app-frame">
        <div className="section-top-rated-apps__logo-wrapper">
          <this.renderIcon />
          <this.renderIconTriplet />
        </div>
        <div className="section-top-rated-apps__info-wrapper">
          <this.renderDescription />
          <div className="rated-apps_btn">
            <this.renderPrice />
            <this.renderViewButton />
          </div>
        </div>
      </div>
    </div>
  }
}

export { AppEntry, TopAppEntry }
