import React from "react"
import PropTypes from "prop-types"

import GatsbyImage from "gatsby-image"

import base64js from "base64-js"

class Image extends React.Component {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    file: PropTypes.shape({
      publicURL: PropTypes.string,
      childImageSharp: PropTypes.object,
    }),

    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    className: "",
    style: {},
    file: {},
  }

  render() {
    const {
      alt,
      file,
      className, style,
    } = this.props

    const {
      publicURL,
      childImageSharp,
      internal,
    } = file

    const { content } = internal || {}

    const completeClass = `image-wrapper ${className}`

    if (childImageSharp) {
      const { fluid } = childImageSharp
      return <GatsbyImage
        className={completeClass}
        alt={alt}
        fluid={fluid}
        style={style}
      />
    }

    let url = publicURL
    if (content) {
      url = "data:image/svg+xml;base64,"
      url += base64js.fromByteArray(Buffer.from(content))
      // url = `data:image/svg+xml;utf-8,<svg>${content}</svg>` // doesn't work
      // console.log({ urlLenght: url.length, u64Length: url64.length })
    }

    if (url) {
      return <div
        className={completeClass}
        style={style}
      >
        <img
          src={url}
          alt={alt}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    }

    console.log("No way to render icon for", alt)
    return null
  }
}

export { Image }
