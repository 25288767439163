const featuredApp = {
  fusion: "trello github youtrack".split(" "),
  extension: [
    "custom-javascript",
    "labs-auto-self-assign",
    "labs-reply-requirements",
  ],
  sso: "json-web-token LDAP data-auth".split(" "),
}

module.exports = { featuredApp }
