const indexTabList = [{
  tabTitle: "Fusion",
  path: "/",
  paragraphText: [
    "Niche market ecosystem release growth hacking",
    "market bootstrapping seed round innovator scrum",
    "project monetization android angel investor disruptive.",
    "Seed round burn rate long tail focus assets rockstar.",
  ].join(" "),
}, {
  tabTitle: "Extension",
  paragraphText: "",
}, {
  tabTitle: "SSO",
  paragraphText: "",
  // }, {
  //   tabTitle: "Zapier",
  //   paragraphTitle: "Zapier",
  //   paragraphText: "",

  // }, {
  //   tabTitle: "Channels",
  //   paragraphTitle: "Channels",
  //   paragraphText: "",
}].map((all) => {
  const { tabTitle } = all
  const { tabName = tabTitle.toLocaleLowerCase() } = all
  const {
    iconName = tabName,
    path = `/${tabName}`,
    paragraphTitle = `${tabTitle} Apps`,
    paragraphText = "",
  } = all
  return {
    ...all,
    iconName,
    tabTitle,
    tabName,
    paragraphTitle,
    paragraphText,
    path,
  }
})


const tabName2pathObj = {}
indexTabList.forEach(({ tabName, path }) => {
  tabName2pathObj[tabName] = path
})

function tabName2path(name) {
  return tabName2pathObj[name]
}


module.exports = { indexTabList, tabName2path }
