import React from "react"
import { PropTypes } from "prop-types"

class IconTriplet extends React.Component {
  static propTypes = {
    active: PropTypes.objectOf(PropTypes.bool).isRequired,
  }

  render() {
    return <span className="app-frame-ico">
      {"agent portal integration".split(" ").map((name) => {
        const activeStr = this.props.active[name] ? " is-active" : ""
        return <svg key={name} className={`svg-ico${activeStr}`}>
          <use xlinkHref={`#icon-${name}`} />
        </svg>
      })}
    </span>
  }
}

export { IconTriplet }
