import React from "react"
import PropTypes from "prop-types"

class TopicDiv extends React.Component {
  static propTypes = {
    topicList: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  static renderTopic(topic) {
    return <li key={topic}>{topic}</li>
  }

  render() {
    return (
      <div>
        <h3>Topics</h3>
        <ul className="topicList">
          {this.props.topicList.map(TopicDiv.renderTopic)}
        </ul>
      </div>
    )
  }
}

export { TopicDiv }
