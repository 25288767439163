/* eslint-disable no-restricted-globals */
import React from "react"
import { Link } from "gatsby"

import { SEO } from "../components"

class NotFoundPage extends React.Component {
  static propTypes = {}

  componentDidMount() {
    const path = decodeURIComponent(location.pathname.slice(1))
    const query = path.replace(/^.*\//, "").replace(/[^a-zA-Z0-9]+/g, " ")
    this.setState({ search: `/#?search=${query}` })
  }

  render() {
    let { state } = this
    if (state === null) {
      state = {}
    }
    const { search = "" } = state
    return (
      <div>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
        You might want to try
          <Link to={search}> searching for it</Link>
        .
        </p>
      </div>
    )
  }
}

export default NotFoundPage
